import React from 'react'

const useStorage = () => {
    const saveToStorage = (userInteractedData: any) => {
        localStorage.setItem('userInteractedData', JSON.stringify(userInteractedData))
    }

    const clearStorage = () => {
        localStorage.removeItem('userInteractedData')
    }

    const getFromStorage = () => {
        const localItem = localStorage.getItem('userInteractedData')
        if (localItem) {
            return JSON.parse(localItem)
        }
        return null
    }

    return { saveToStorage, getFromStorage, clearStorage }
}

export default useStorage