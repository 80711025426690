import React, { useState, createContext, useEffect, useRef } from 'react'
import InteractedTree from './Tree/InteractedTree'
import { Button, Drawer, Modal, message } from 'antd';
import { MenuOutlined } from '@ant-design/icons'
import InfoForm from './InfoForm/InfoForm';
import useStorage from '../Services/useStorage';
import { useChecker } from '../Services/useChecker';
import useVersion from '../Services/useVersion';
import OldVer from './OldVer/OldVer';
import "./Main.scss"
import Admin from './Admin/Admin';

type Props = {}

const defaultPlantProgress = {
    name: '',
    mssv: '',
    password: '',
    numberOfWater: 0,
    lastWatered: null,
    receivedGift: false,
}

export const Data = createContext<any>(null);

const MainLayout = (props: Props) => {
    const { getFromStorage, clearStorage } = useStorage()
    const { checkDevTool } = useChecker()
    const { checkVersion } = useVersion()
    const [userPlantProgress, setUserPlantProgress] = useState(getFromStorage() ? getFromStorage() : defaultPlantProgress)
    const [modalState, setModalState] = useState<boolean>(false);
    const [instructionState, setInstructionState] = useState<boolean>(false);
    const [drawerState, setDrawerState] = useState<boolean>(false)
    const [oldVer, setOldVer] = useState<boolean>(false)

    const menuRef = useRef(null)

    useEffect(() => {
        checkDevTool()
    })

    useEffect(() => {
        if (userPlantProgress.name.trim().length >= 7) {
            setModalState(false)
        } else {
            setModalState(true)
        }
    }, [userPlantProgress])

    const handleLogout = () => {
        clearStorage()
        setUserPlantProgress(defaultPlantProgress)
        message.success(`Đã đăng xuất khỏi tài khoản ${userPlantProgress.mssv}`)
        setDrawerState(false)
        window.location.reload();
    }

    const handleShowInstruction = () => {
        setDrawerState(!drawerState)
        setInstructionState(!instructionState)
    }

    return (
        <div>
            <Modal footer={null} closable={false} open={oldVer}>
                <OldVer />
            </Modal>

            {/* Instruction */}
            {/* <Modal title="HƯỚNG DẪN TRỒNG CÂY" footer={null} open={instructionState} onCancel={() => setInstructionState(false)} >
                Xin chào bạn đến với Ladyland, tớ là <b>Wonder Flower</b> - một loài hoa tuyệt sắc mang trong mình sứ mệnh lan tỏa và duy trì hạnh phúc cho tất cả phái đẹp trên thế giới này.
                <br /><br />
                Nhưng hiện tại tớ không thể tự mình phát triển. Để đủ năng lượng ươm mầm tỏa sắc, tớ cần sự giúp sức từ bạn. Sau mỗi <b>30 phút</b>, bạn hãy dành chút thời gian nhấn vào “chậu cây” tưới nước và chăm sóc giúp tớ.
                <br /><br />
                Đừng lo, sau khi đã chăm sóc đủ 32 lần và chinh phục được 3 cấp bậc, tớ sẽ nở hoa và trưởng thành. Khi đó, bạn hãy nhanh chóng mang tớ đến chủ của vùng đất này để được ban những món quà giá trị nhé!
            </Modal> */}

            <Data.Provider value={{ userPlantProgress, setUserPlantProgress, setInstructionState }}>
                <Button ref={menuRef} onClick={() => setDrawerState(true)} type="text" className='menu'><MenuOutlined className='menuBtn' /></Button>

                <Drawer title="Menu" open={drawerState} onClose={() => setDrawerState(false)}
                    placement={/iPhone|Android|Windows Phone|IPad|IPod/.test(navigator.userAgent) ? 'top' : 'right'}
                    className={/iPhone|Android|Windows Phone|IPad|IPod/.test(navigator.userAgent) ? 'mobileDrawer' : 'pcDrawer'}
                >
                    <Button type="text" className='drawerBtn' onClick={handleShowInstruction}>Hướng dẫn</Button>
                    <Button type="text" className='drawerBtn'><a href="https://www.facebook.com/cocsaigonfuhcm" target="_blank">Liên hệ</a></Button>
                    <Button type="text" className='drawerBtn' danger onClick={handleLogout}>Đăng xuất</Button>
                </Drawer>
                <Modal title="Thông tin" open={true} footer={null} closable={false}>
                    Sự kiện đã kết thúc
                    {/* <InfoForm /> */}
                </Modal>

                {/* CONDITIONAL RENDERING */}
                {
                    userPlantProgress.mssv === "ADMINCSG"
                        ?
                        // ADMIN
                        <Admin />
                        :
                        // USERS
                        <InteractedTree userPlantProgress={userPlantProgress} menuRef={menuRef} instructionState={instructionState} setInstructionState={setInstructionState} />
                }
            </Data.Provider>
        </div >
    )
}

export default MainLayout