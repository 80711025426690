import "../Tree.scss"

type Props = {
    grow: number
}

const Flower = ({grow}: Props) => {
    const stemHeight = [30, 60, 80, 100, 120, 150, 150]
    return (
        grow === 0 ?
            <></>
            :
            <div className="flower">
                <div className='stem' style={{ height: stemHeight[grow - 1] }}></div>
                <div className={`leaf leaf${grow}`}></div>
                <div className={`leaf leaf${grow}`}></div>
                {grow >= 3 && <div className={`leaf leaf${grow}`}></div>}
                {grow >= 6 && <div className="dot"></div>}
                {grow >= 7 &&
                    <>
                        <div className="petal petal-1"></div>
                        <div className="petal petal-2"></div>
                        <div className="petal petal-3"></div>
                        <div className="petal petal-4"></div>
                        <div className="petal petal-5"></div>
                        <div className="petal petal-6"></div>
                    </>
                }
                {/* <button onClick={() => setGrow(grow + 1)}></button> */}
            </div>
    )
}

export default Flower