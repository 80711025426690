import "../Board.scss"

type Props = {
    name: string;
}

const Board = ({ name }: Props) => {
    return (
        <>
            <div className="main-wrapper">
                <div className="signboard-wrapper">
                    <div className="signboard">{name}</div>
                    <div className="string"></div>
                    {/* <div className="pin pin1"></div> */}
                    {/* <div className="pin pin2"></div>
                    <div className="pin pin3"></div> */}
                </div>
            </div>
        </>
    )
}

export default Board