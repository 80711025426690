import axios from 'axios'
import useStorage from './useStorage'

const endPoint = 'https://657dba623e3f5b1894630954.mockapi.io/lovelottCheckVer'
const version = 1.3

const useVersion = () => {
    const { clearStorage } = useStorage()
    const checkVersion = (setOldVer: (valud: boolean) => void) => {
        axios.get(endPoint)
            .then((res) => {
                setOldVer(res.data[1].version !== version)
                clearStorage()
                window.location.reload();
            })
            .catch((err) => console.log(err))
    }

    return { checkVersion }
}



export default useVersion