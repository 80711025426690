import React from 'react'
import "../Cow.scss"
import { HeartFilled } from '@ant-design/icons'
type Props = {
    active: boolean
}

const Cow = ({ active }: Props) => {
    return (
        <>
            <div className="cowContainer" style={active ? {} : { animation: 'none' }}>
                <div className="cow-body">
                    <div className="clip-wrapper">
                        <div className="cow-udder"></div>
                        <div className="arms right-arm"></div>
                        <div className="arms left-arm"></div>

                        <div className="spot-1"></div>
                        <div className="spot-2"></div>
                        <div className="spot-3"></div>
                        <div className="spot-4"></div>
                        <div className="spot-5"></div>
                        <div className="spot-6"></div>
                    </div>
                    <div className="eyes eye-1"></div>
                    <div className="eyes eye-2"></div>

                    <div className="smile-1"></div>
                    <div className="smile-2"></div>

                    <div className="ears left-ear"></div>
                    <div className="ears right-ear"></div>

                    <div className="horns left-horn"></div>
                    <div className="horns right-horn"></div>

                    <div className="muzzle">
                        <div className="nostril-1"></div>
                        <div className="nostril-2"></div>
                    </div>

                    <div className="feet left-foot"></div>
                    <div className="feet right-foot"></div>

                    <div className="teats teat-1"></div>
                    <div className="teats teat-2"></div>
                    <div className="teats teat-3"></div>

                    <div className="head"></div>
                    <div className="tuners tuner-1"></div>
                    <div className="tuners tuner-2"></div>
                    <div className="tuners tuner-3"></div>
                    <div className="tuners tuner-4"></div>
                    <div className="neck"></div>
                    <div className="cuatro-body-1"></div>
                    <div className="cuatro-body-2"></div>
                    <div className="cuatro-body-3"></div>
                    <div className="sound-hole"></div>
                    <div className="bridge"></div>
                    <div className="strings string-1"></div>
                    <div className="strings string-2"></div>
                    <div className="strings string-3"></div>
                    <div className="strings string-4"></div>

                    <div className="wing-front-1"></div>
                    <div className="wing-front-2"></div>
                    <div className="wing-front-3"></div>
                    <div className="wing-back-1"></div>
                    <div className="wing-back-2"></div>
                </div>
                <div className="note-1"><HeartFilled /></div>
                <div className="note-2"><HeartFilled /></div>
                <div className="note-3"><HeartFilled /></div>
                <div className="note-4"><HeartFilled /></div>
            </div>
        </>
    )
}

export default Cow