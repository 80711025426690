import "../ButterFly.scss"

type Props = {}

const Butterfly = (props: Props) => {
    return (
        <div className="butterfly fly">
            <div className="wingR flutterR">
                <div className="top">
                </div>
                <div className="bottom">
                </div>
            </div>
            <div className="wingL flutterL">
                <div className="top">
                </div>
                <div className="bottom">
                </div>
            </div>
        </div>
    )
}

export default Butterfly