import { Input, Space, Button, message, Alert, Modal, Row, Col, Tag, Spin } from "antd"
import "./Admin.scss"
import { useState } from "react"
import useSheet from "../../Services/useSheet"

type Props = {}

const Admin = (props: Props) => {
    const [input, setInput] = useState<string>('')

    const [loading, setLoading] = useState<boolean>(false)
    const [updateGift, setUpdateGift] = useState<boolean>(false)
    const [data, setData] = useState<any>(null)
    const [modal, setModal] = useState<boolean>(false)

    const { adminCheckData, handleReceivedGift } = useSheet()

    const handleContinue = () => {
        if (input.trim().length < 7) {
            message.error('Bạn vui lòng chọn vào ô trắng, sau đó quét QR')
            return
        }

        adminCheckData(input, setData, setLoading)
        setModal(true)
        setInput('')
        setData(null)
    }

    const handleDone = () => {
        handleReceivedGift(data, setUpdateGift, setModal, setData)
    }
    return (
        <div className="admin">
            <Modal title="THÔNG TIN" open={modal} closable={false} footer={null}>
                <Spin spinning={loading}>
                    {
                        data
                            ?
                            <>
                                <Row className="dataRow">
                                    <Col span={8}><b>Họ tên:</b></Col>
                                    <Col span={15}>{data.name}</Col>
                                </Row>
                                <Row className="dataRow">
                                    <Col span={8}><b>MSSV:</b></Col>
                                    <Col span={15}>{data.mssv}</Col>
                                </Row>
                                <Row className="dataRow">
                                    <Col span={8}><b>Số lần tưới:</b></Col>
                                    <Col span={15}>{data.numberOfWater}/32</Col>
                                </Row>
                                <Row className="dataRow">
                                    <Col span={8}><b>Lần tưới cuối:</b></Col>
                                    <Col span={15}>{data.lastWatered}</Col>
                                </Row>
                                <Row className="dataRow">
                                    <Col span={8}><b>Trạng thái:</b></Col>
                                    <Col span={15}>
                                        {
                                            data.numberOfWater === 32
                                                ?
                                                data.receivedGift
                                                    ?
                                                    <Tag color="red">Đã nhận</Tag>
                                                    :
                                                    <Tag color="green">Chưa nhận</Tag>
                                                :
                                                <Tag color="red">Chưa đủ điều kiện</Tag>
                                        }
                                    </Col>
                                </Row>
                                <Row className="dataRow">
                                    <Col span={8}></Col>
                                    <Col span={16}>
                                        <Button style={{ margin: '0 5px 0 0' }} onClick={() => setModal(false)}>Huỷ</Button>
                                        {!data.receivedGift && data.numberOfWater === 32 && <Button type="primary" onClick={handleDone} loading={updateGift}>Đã đưa quà</Button>}
                                    </Col>
                                </Row>
                            </>
                            :
                            !loading && <>
                                <Alert message="MSSV không tồn tại" type="error"></Alert>
                                <Button style={{ margin: '20px 0 0 0' }} onClick={() => setModal(false)}>Huỷ</Button>
                            </>
                    }
                </Spin>
            </Modal>
            <Space.Compact className="inputContainer">
                <Input autoFocus value={input} onChange={(e) => setInput(e.target.value.toUpperCase())} className="input" placeholder="Chọn vào ô này sau đó quét QR" />
                <Button type="primary" onClick={handleContinue} loading={loading}>Tiếp tục</Button>
            </Space.Compact>
            <ol className="instructionAdmin">
                <li>Click vào <b>ô trắng</b></li>
                <li>Quét mã QR của các bạn đến nhận quà</li>
                <li>Bấm tiếp tục</li>
                <li>Nếu muốn <b style={{ color: 'red' }}>quay lại</b>, click <b style={{ color: 'red' }}>"Huỷ"</b></li>
                <li>Nếu <b style={{ color: 'green' }}>đã đưa quà</b>, click <b style={{ color: 'green' }}>"Đã đưa quà"</b></li>
            </ol>
        </div>
    )
}

export default Admin