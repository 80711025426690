import { message } from "antd"
import axios from "axios"
import useDataInteracted from "./useDataInteracted"
import useStorage from "./useStorage"

const endPoint = 'https://sheet.best/api/sheets/2e5531b6-a7a7-4631-8348-6dcc372abae4'
const adminEndPoint = 'https://sheetdb.io/api/v1/kfupcag8rrrqh'

const useSheet = () => {
    const { updateAllData, setInstructionState } = useDataInteracted()
    const { clearStorage } = useStorage()

    const register = (name: string, mssv: string, password: string, setLoading: (value: boolean) => void) => {
        clearStorage()
        const data = {
            mssv: mssv.toUpperCase(),
            name: name,
            password: password,
            data: {
                numberOfWater: 0,
                lastWatered: null,
                receivedGift: false,
            }
        }
        setLoading(true)
        axios.get(`${endPoint}/search?mssv=${mssv.toUpperCase()}`)
            .then((res) => {
                const loggedData = res.data

                // Check if mssv existed
                let mssvExisted = false
                if (loggedData.length === 0) {
                    mssvExisted = false
                } else {
                    loggedData.forEach((data: any) => {
                        if (data.mssv.toUpperCase() === mssv.toUpperCase()) {
                            mssvExisted = true
                        }
                    })
                }

                if (mssvExisted) {
                    message.error('MSSV này đã đăng ký tài khoản rồi')
                    setLoading(false)
                } else {
                    axios.post(endPoint, data)
                        .then((res) => {
                            message.success('Đăng ký thành công')
                            updateAllData(name, mssv, password, 0, null, false)
                            setLoading(false)
                            setInstructionState(true)
                        })
                        .catch((err) => console.log(err))
                }
            })
            .catch((err) => console.log(err))
    }

    const login = (mssv: string, password: string, setLoading: (value: boolean) => void) => {
        clearStorage()
        setLoading(true)
        axios.get(`${endPoint}/search?mssv=${mssv}`)
            .then((res) => {
                // Data
                const userData = res.data
                let existedAccount = false
                let userInfo = {
                    name: '',
                    mssv: '',
                    password: '',
                    data: '',
                    numberOfWater: 0,
                    lastWatered: null,
                    receivedGift: false,
                }

                // Validate
                if (userData.length === 0) {
                    // message.error('MSSV hoặc Mật khẩu không đúng')
                } else {
                    userData.forEach((data: any) => {
                        if (data.mssv === mssv && data.password === password) {
                            existedAccount = true
                            userInfo = data
                        }
                    })
                }

                if (existedAccount) {
                    const parsedData = JSON.parse(userInfo.data)
                    message.success('Đăng nhập thành công')
                    updateAllData(userInfo.name, mssv, password, parsedData.numberOfWater, parsedData.lastWatered, parsedData.receivedGift)
                } else {
                    message.error('MSSV hoặc Mật khẩu không đúng')
                }
            })
            .catch((err) => console.log(err))
            .finally(() => setLoading(false))
    }

    const updateData = (mssv: string, data: any) => {
        axios.put(`${endPoint}/mssv/${mssv}`, data)
            .then((res) => console.log(res))
            .catch((err) => console.log(err))
    }

    const getQrCode = (mssv: string, setLoading: (value: boolean) => void, setGiftState: any) => {
        setLoading(true)
        axios.get(`${endPoint}/search?mssv=${mssv}`)
            .then((res) => {
                const data = res.data
                data.forEach((data: any) => {
                    if (mssv === data.mssv) {
                        setGiftState({
                            qrCode: data.qrcode,
                            received: data.received,
                        })
                    }
                })
            })
            .catch((err) => console.log(err))
            .finally(() => setLoading(false))
    }

    const adminCheckData = (mssv: string, setData: (data: any) => void, setLoading: (value: boolean) => void) => {
        setLoading(true)
        axios.get(`${adminEndPoint}/search?mssv=${mssv.toUpperCase()}`)
            .then((res) => {
                const data = res.data
                if (data) {
                    const parsedData = JSON.parse(data[0].data)
                    const lastWatered = new Date(parsedData.lastWatered)
                    const timeString = `${lastWatered.getHours() < 10 ? `0${lastWatered.getHours()}` : lastWatered.getHours()}:${lastWatered.getMinutes() < 10 ? `0${lastWatered.getMinutes()}` : lastWatered.getMinutes()} ${lastWatered.getDate() < 10 ? `0${lastWatered.getDate()}` : lastWatered.getDate()}/${lastWatered.getMonth() + 1 < 10 ? `0${lastWatered.getMonth() + 1}` : lastWatered.getMonth() + 1}/${lastWatered.getFullYear()}`
                    const returnedData = {
                        name: data[0].name,
                        mssv: data[0].mssv,
                        password: data[0].password,
                        numberOfWater: parsedData.numberOfWater,
                        lastWatered: timeString,
                        ISOLastWatered: parsedData.lastWatered,
                        receivedGift: parsedData.receivedGift
                    }
                    setData(returnedData)
                } else {
                    setData([])
                }
            })
            .catch((err) => console.log(err))
            .finally(() => setLoading(false))
    }

    const handleReceivedGift = (data: any, setLoading: (value: boolean) => void, setModal: (value: boolean) => void, setData: any) => {
        const updateData = {
            mssv: data.mssv.toUpperCase(),
            name: data.name,
            password: data.password,
            data: {
                numberOfWater: data.numberOfWater,
                lastWatered: data.ISOLastWatered,
                receivedGift: true,
            }
        }
        setLoading(true)
        axios.put(`${endPoint}/mssv/${updateData.mssv}`, updateData)
            .then((res) => message.success('Đã cập nhật trạng thái thành công'))
            .catch(error => message.error('Có lỗi xảy ra, vui lòng liên hệ team web'))
            .finally(() => {
                setLoading(false)
                setModal(false)
                setData(null)
            })
    }

    return { register, login, updateData, getQrCode, adminCheckData, handleReceivedGift }
}

export default useSheet