import { useEffect, useState } from "react"
import "../Bee.scss"
import { motion } from 'framer-motion'

type Props = {
    active: boolean
}

const init = { opacity: 0 }
const ani = { opacity: 1 }
const trans = { duration: 0.5 }

const Bee = ({ active }: Props) => {
    let treeContainerWidth = 350;
    let beeMaxFly = treeContainerWidth / 2
    const [beeCoor, setBeeCoor] = useState({ x: Math.random() * beeMaxFly, y: Math.random() * beeMaxFly })

    const updateCoor = () => {
        let randomPosix = Math.random() < 0.4 ? -1 : 1.1
        let randomPosiy = Math.random() < 0.5 ? -1 : 1

        let x = Math.random() * beeMaxFly * randomPosix
        let y = Math.random() * beeMaxFly * randomPosiy
        setBeeCoor({ x, y })
    }

    useEffect(() => {
        if (active) {

            let coorTimeout = setTimeout(() => {
                updateCoor()
                clearTimeout(coorTimeout)
            }, 100)

            let beeInterval = setInterval(() => {
                updateCoor()
            }, 1000 * 5)
            return () => {
                clearInterval(beeInterval)
            }
        }

    }, [])

    return (
        <div
            className="bee" style={Object.assign(active ? { top: `calc(40% + ${beeCoor.y}px)` } : {}, active ? { left: `calc(35% + ${beeCoor.x}px)` } : {})}>
            <div className="bee__body">
                <div className="bee__circle bee__circle--black"></div>
                <div className="bee__circle bee__circle--yellow helper__shift--right-1"></div>
                <div className="bee__circle bee__circle--black helper__shift--right-2"></div>
                <div className="bee__circle bee__circle--yellow helper__shift--right-3"></div>
                <div className="bee__circle bee__circle--black helper__shift--right-4 bee__face">
                    <div className="bee__eyes">
                        <span className="bee__eye"></span>
                        <span className="bee__eye"></span>
                    </div>
                    <div className="bee__antennas">
                        <span className="bee__antenna bee__antenna--left"></span>
                        <span className="bee__antenna bee__antenna--right"></span>
                    </div>
                </div>
                <div className="bee__wings">
                    <span className="bee__wing bee__wing--left"></span>
                    <span className="bee__wing bee__wing--right"></span>
                </div>
            </div>
        </div>
    )
}

export default Bee