import { useContext } from "react"
import { Data } from "../Layout/MainLayout"
import useStorage from "./useStorage"
import useSheet from "./useSheet"

const useDataInteracted = () => {
    const { userPlantProgress, setUserPlantProgress, setShowWaterBtn, setInstructionState} = useContext(Data) as any
    const { saveToStorage, clearStorage } = useStorage()
    // const { updateData } = useSheet()

    const increaseWatered = () => {
        setUserPlantProgress({ ...userPlantProgress, numberOfWater: userPlantProgress.numberOfWater + 1 })
    }

    const updateAllData = (name: string, mssv: string, password: string, numberOfWater: number, lastWatered: null | Date, receivedGift: boolean) => {
        let prog = {
            name: name,
            mssv: mssv,
            password: password,
            numberOfWater: numberOfWater,
            lastWatered: lastWatered,
            receivedGift: receivedGift,
        }
        saveToStorage(prog)
        setUserPlantProgress(prog)
    }

    const updateWateredTime = (newTime: Date) => {

        const timeOutWater = setTimeout((): any => {
            setUserPlantProgress({ ...userPlantProgress, numberOfWater: userPlantProgress.numberOfWater + 1, lastWatered: newTime, })
            clearTimeout(timeOutWater)
        }, 1000 * 2.5)

        const newSavedData = {
            name: userPlantProgress.name,
            mssv: userPlantProgress.mssv,
            password: userPlantProgress.password,
            numberOfWater: userPlantProgress.numberOfWater + 1,
            lastWatered: newTime,
            receivedGift: userPlantProgress.receivedGift,
        }

        saveToStorage(newSavedData)
        // updateData(newSavedData.mssv, newSavedData)
    }

    const updateGiftState = (newState: boolean) => {
        const newData = { ...userPlantProgress, receivedGift: newState }
        setUserPlantProgress(newData)
        return newData
    }

    return { increaseWatered, updateAllData, updateWateredTime, updateGiftState, setInstructionState }
}

export default useDataInteracted