import { useState, useEffect, useRef } from "react"
import Flower from "./Elements/Flower"
import "./Tree.scss"
import useDataInteracted from "../../Services/useDataInteracted"
import Board from "./Elements/Board"
import useSheet from "../../Services/useSheet"
import { motion, AnimatePresence } from 'framer-motion'
import { GiftFilled, LockFilled } from '@ant-design/icons'
import { Modal, Popover, QRCode, message, Tour } from "antd"
import type { TourProps } from 'antd';
import Bee from "./Elements/Bee"
import Cow from "./Elements/Cow"
import Butterfly from "./Elements/Butterfly"
import { Fireworks } from '@fireworks-js/react'
import type { FireworksHandlers } from '@fireworks-js/react'

type Props = {
    userPlantProgress: any
    menuRef: any,
    instructionState: boolean,
    setInstructionState: (value: boolean) => void
}

const initAni = { opacity: 0 }
const inAni = { opacity: 1 }

const beeUnlock: number = 10
const ButterflyUnlock: number = 20
const cowUnlock: number = 30

// Default Watered Time
const time1 = new Date();
time1.setSeconds(0);
time1.setHours(14);
time1.setMinutes(8);
time1.setDate(24);
time1.setMonth(1);
time1.setFullYear(2024);

const InteractedTree = ({ userPlantProgress, menuRef, setInstructionState, instructionState }: Props) => {
    const [water, setWater] = useState<boolean>(false)
    const [grow, setGrow] = useState<number>(0)

    const [time, setTime] = useState<string>('--:--:--')
    const [showWater, setShowWater] = useState<boolean>(false)
    const [wateredTime, setWateredTime] = useState<Date>(userPlantProgress.lastWatered ? userPlantProgress.lastWatered : time1)

    const [modalState, setModalState] = useState<boolean>(false)

    const [showBee, setShowBee] = useState<boolean>(false)
    const [showCow, setShowCow] = useState<boolean>(false)

    const [showButterfly, setShowButterfly] = useState<boolean>(false)

    const [showFirework, setShowFirework] = useState<boolean>(false)
    const fireWork = useRef<FireworksHandlers>(null)

    const [open, setOpen] = useState<boolean>(false)

    const cowMusicRef = useRef<HTMLAudioElement>(null);
    const [isMusicPlay, setIsMusicPlay] = useState<boolean>(false)

    const { updateWateredTime } = useDataInteracted()
    const { updateData } = useSheet()

    // Ref for instruction tour
    const potRef = useRef(null)
    const waterBottleRef = useRef(null)
    const progressRef = useRef(null)
    const beeRef = useRef(null)
    const butterFlyRef = useRef(null)
    const cowRef = useRef(null)

    const steps: TourProps['steps'] = [
        {
            title: 'Chậu cây',
            description: 'Cây của bạn sẽ mọc tại đây',
            target: () => potRef.current,
            nextButtonProps: { children: 'Tiếp' },
            placement: 'bottom',
        },
        {
            title: 'Bình tưới nước',
            description: 'Cứ mỗi 30 phút bạn sẽ được tưới cây 1 lần, bạn bấm vào đây để tưới cây',
            target: () => waterBottleRef.current,
            nextButtonProps: { children: 'Tiếp' },
            prevButtonProps: { children: 'Quay lại' }
        },
        {
            title: 'Thanh tiến độ',
            description: 'Có tổng 32 lần tưới, khi bạn tưới đủ 32 lần, thanh tiến độ sẽ đầy và bạn có thể chạm vào đây để lấy QR nhận quà',
            target: () => progressRef.current,
            nextButtonProps: { children: 'Tiếp' },
            prevButtonProps: { children: 'Quay lại' }
        },
        {
            title: 'Ong',
            description: 'Ong sẽ được mở khoá khi bạn đã tưới cây đủ 10 lần, bạn có thể bật/tắt ong bằng cách chạm vào nút này',
            target: () => beeRef.current,
            nextButtonProps: { children: 'Tiếp' },
            prevButtonProps: { children: 'Quay lại' }
        },
        {
            title: 'Bướm',
            description: 'Bướm sẽ được mở khoá khi bạn đã tưới cây đủ 20 lần, bạn có thể bật/tắt bướm bằng cách chạm vào nút này',
            target: () => butterFlyRef.current,
            nextButtonProps: { children: 'Tiếp' },
            prevButtonProps: { children: 'Quay lại' }
        },
        {
            title: 'Bò',
            description: 'Bò sẽ được mở khoá khi bạn đã tưới cây đủ 30 lần, đặc biệt hơn, bò sẽ có nhạc, bạn có thể bật/tắt Bò bằng cách chạm vào nút này',
            target: () => cowRef.current,
            nextButtonProps: { children: 'Tiếp' },
            prevButtonProps: { children: 'Quay lại' }
        },
        {
            title: 'Menu',
            description: 'Tại đây bạn có thể bật lại hướng dẫn này, liên hệ hỗ trợ & đăng xuất tài khoản',
            target: () => menuRef.current,
            nextButtonProps: { children: 'Xong' },
            prevButtonProps: { children: 'Quay lại' }
        },
    ];

    // Validate that user have logged in -> if there is a data of last water time -> Update it to state
    useEffect(() => {
        if (userPlantProgress.mssv !== '') {
            const timeOutShow = setTimeout((): any => {
                setShowWater(true)
                clearTimeout(timeOutShow)
            }, 1000)
        }
        setWateredTime(userPlantProgress.lastWatered && userPlantProgress.lastWatered)
    }, [userPlantProgress])

    // Applying Grow Progress on Flower
    useEffect(() => {
        let currNumOfWater = userPlantProgress.numberOfWater
        if (currNumOfWater > 0 && currNumOfWater < 5) {
            setGrow(1)
        } else if (currNumOfWater >= 5 && currNumOfWater < 10) {
            setGrow(2)
        } else if (currNumOfWater >= 10 && currNumOfWater < 15) {
            setGrow(3)
        } else if (currNumOfWater >= 15 && currNumOfWater < 19) {
            setGrow(4)
        } else if (currNumOfWater >= 19 && currNumOfWater < 25) {
            setGrow(5)
        } else if (currNumOfWater >= 25 && currNumOfWater < 32) {
            setGrow(6)
        } else if (currNumOfWater === 32) {
            setGrow(7)
        }
    }, [userPlantProgress.numberOfWater])

    useEffect(() => {

        const formatTime = (seconds: number) => {
            const hours = Math.floor(seconds / 3600)
            const minutes = Math.floor((seconds % 3600) / 60)
            const remainingSeconds = Math.floor(seconds % 60)

            const formattedHours = String(hours).padStart(2, "0")
            const formattedMinutes = String(minutes).padStart(2, "0")
            const formattedSeconds = String(remainingSeconds).padStart(2, "0")

            return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`
        }

        let waterTimeInterval = setInterval(() => {
            //Get 30 minutes from time1
            let parsedTime = new Date(wateredTime)
            const nextWaterTime = new Date(parsedTime.getTime() + 30 * 60000)

            // Get current time
            const now = new Date()
            let diff = nextWaterTime.getTime() - now.getTime()
            let second = diff / 1000

            let currDiffInString = formatTime(second)
            let currDiffInArr = currDiffInString.split(":")
            if (currDiffInString === '00:00:00' || Number(currDiffInArr[2]) < 0) {
                setTime('00:00:00')
                clearInterval(waterTimeInterval)
            } else {
                setTime(currDiffInString)
            }
        }, 1000)

    }, [wateredTime])


    const waterToPlant = () => {
        if (water || time !== '00:00:00') {
            return
        }

        if (!navigator.onLine) {
            message.error('Không có kết nối internet')
            return
        }

        // Update
        let currTime = new Date()
        setWateredTime(currTime)
        updateWateredTime(currTime)

        const newSavedData = {
            name: userPlantProgress.name,
            mssv: userPlantProgress.mssv,
            password: userPlantProgress.password,
            data: {
                numberOfWater: userPlantProgress.numberOfWater + 1,
                lastWatered: currTime,
                receivedGift: userPlantProgress.receivedGift,
            }
        }
        updateData(newSavedData.mssv, newSavedData)

        setWater(true)
        const waterTimeout = setTimeout(() => {
            if (newSavedData.data.numberOfWater === 32) {
                showFireWork()
                message.success('Chúc mừng bạn đã chinh phục thử thách thành công')
            }

            if (userPlantProgress.numberOfWater + 1 === beeUnlock) {
                message.success('Chúc mừng bạn đã mở khoá ONG')
            }
            if (userPlantProgress.numberOfWater + 1 === ButterflyUnlock) {
                message.success('Chúc mừng bạn đã mở khoá BƯỚM')
            }
            if (userPlantProgress.numberOfWater + 1 === cowUnlock) {
                message.success('Chúc mừng bạn đã mở khoá BÒ')
            }
            setWater(false)
            clearTimeout(waterTimeout)
        }, 1000 * 4)
    }

    const handleGift = () => {
        if (!navigator.onLine) {
            message.error('Không có kết nối internet')
            return
        }

        if (userPlantProgress.numberOfWater === 32) {
            setModalState(true)
        }
    }

    const handleShowBee = () => {
        if (userPlantProgress.numberOfWater >= beeUnlock) {
            setShowBee(!showBee)
        }
    }

    const handleShowButterfly = () => {
        if (userPlantProgress.numberOfWater >= ButterflyUnlock) {
            setShowButterfly(!showButterfly)
        }
    }

    const handleShowCow = () => {
        if (userPlantProgress.numberOfWater >= cowUnlock) {
            if (isMusicPlay) {
                setIsMusicPlay(!isMusicPlay)
                cowMusicRef.current?.pause()
            } else {
                setIsMusicPlay(!isMusicPlay)
                cowMusicRef.current?.play()
            }

            setShowCow(!showCow)
        }
    }

    const showFireWork = () => {
        setShowFirework(true)
        const fwTimeout = setTimeout(() => {
            setShowFirework(false)
            clearTimeout(fwTimeout)
        }, 1000 * 3)
    }

    const handleFinishInstruction = () => {
        setOpen(false)
        setInstructionState(false)
    }
    return (
        <div>
            {/* Instruction Tour */}
            <Tour open={open || instructionState} onClose={() => { handleFinishInstruction() }} steps={steps} />

            {/*  Cow Audio */}
            <audio src="./Assets/Audio/cowmusic.mov" loop ref={cowMusicRef} />

            {/* QR Modal */}
            <Modal title="NHẬN QUÀ" footer={null} onCancel={() => setModalState(false)} open={modalState}>
                <div className="qrContainer">
                    <QRCode value={userPlantProgress.mssv} />
                </div>
                <div className="instruction">
                    <b>Hướng dẫn nhận quà</b>
                    <ol>
                        <li><b>Thời gian:</b> 08:00 - 17:00 08/03/2023</li>
                        <li><b>Địa điểm:</b>  Quầy đổi quà tại lầu 6, <a target="_blank" href="https://www.google.com/maps/dir//VRG2%2B27X+Nh%C3%A0+V%C4%83n+h%C3%B3a+Sinh+vi%C3%AAn+TP.HCM,+L%C6%B0u+H%E1%BB%AFu+Ph%C6%B0%E1%BB%9Bc,+%C4%90%C3%B4ng+Ho%C3%A0,+D%C4%A9+An,+B%C3%ACnh+D%C6%B0%C6%A1ng,+Vi%E1%BB%87t+Nam/@10.8219632,106.6554892,12z/data=!4m8!4m7!1m0!1m5!1m1!1s0x3174d8a6b19d6763:0x143c54525028b2e!2m2!1d106.8007234!2d10.8751238?hl=vi-VN&entry=ttu"><u>Nhà văn hoá sinh viên</u></a></li>
                        <li>BTC sẽ quét <b>QR Code</b> bên trên để thực hiện thủ tục đổi quà</li>
                        <li><b>Lưu ý:</b> Mỗi QR Code chỉ quét được 1 lần</li>
                    </ol>
                </div>
            </Modal>

            {/* Plant container */}
            <div className="container">

                <Board name={userPlantProgress.name} />

                <div className="sun">
                    <div className="sunrays"></div>
                    <div className="circle"></div>
                </div>

                {/* Pot & Shadow */}
                <div className="shadow"></div>
                <div className="pot" ref={potRef}></div>
                <a href="https://www.facebook.com/cocsaigonfuhcm" target="_blank"><img src="./Assets/logowhite.png" className="logoCSG" /></a>
                <a href="https://www.facebook.com/cocsaigonfuhcm" target="_blank"><img src="./Assets/logolld.png" className="logolld" /></a>

                {/* Water */}
                {water &&
                    <>
                        <div className="water-jar"></div>
                        <div className="water"></div>
                    </>
                }

                <Flower grow={grow} />

                {/* Progress Bar */}
                <div className="progressContainer" ref={progressRef} onClick={handleGift} >
                    <div className="progress">
                        <div className="progressInside" style={{ width: `${userPlantProgress.numberOfWater / 32 * 100}%` }}></div>
                        {
                            userPlantProgress.numberOfWater && <div className="numProg">
                                {userPlantProgress.numberOfWater === 32 ? <span style={{ cursor: 'pointer' }}>CHẠM ĐỂ NHẬN QUÀ</span> : `${userPlantProgress.numberOfWater}/32`}
                            </div>
                        }
                    </div>
                    <GiftFilled className="gift" style={{ color: userPlantProgress.numberOfWater === 32 ? 'green' : 'grey' }} />
                </div>

                {/* Water Bottole on the left of the pot */}
                {showWater && userPlantProgress.numberOfWater < 32 &&
                    <motion.div
                        ref={waterBottleRef}
                        initial={initAni}
                        animate={inAni}
                        className="waterContainer" onClick={waterToPlant}>
                        <div className="water-jar2"></div>
                        <div className="countdown">{time === '00:00:00' ? 'Chạm để tưới' : time}</div>
                    </motion.div>
                }

            </div>

            {/* Bee */}
            <div ref={beeRef} className={`${userPlantProgress.numberOfWater >= beeUnlock ? 'animalBtnContainerUnlocked' : 'animalBtnContainer'} ${userPlantProgress.numberOfWater >= beeUnlock ? showBee ? "activePet" : "unactivePet" : ''}`} onClick={handleShowBee}>
                <Popover content={`Ong sẽ được mở khoá ở cấp ${beeUnlock}`} placement="top">
                    <LockFilled className="lock" />
                </Popover>
                <Bee active={false} />
            </div>
            {showBee &&
                <>
                    <Bee active />
                    <Bee active />
                </>
            }

            {/* Butterfly */}
            <div
                ref={butterFlyRef}
                style={{ transform: 'translateY(-55px)' }}
                className={`${userPlantProgress.numberOfWater >= ButterflyUnlock ? 'animalBtnContainerUnlocked' : 'animalBtnContainer'} ${userPlantProgress.numberOfWater >= ButterflyUnlock ? showButterfly ? "activePet" : "unactivePet" : ''}`} onClick={handleShowButterfly}>
                <Popover content={`Bướm sẽ được mở khoá ở cấp ${ButterflyUnlock}`} placement="top">
                    <LockFilled className="lock" />
                </Popover>
                <Butterfly />
            </div>
            {
                showButterfly && <Butterfly />
            }

            {/* Cow */}
            <div
                ref={cowRef}
                style={{ transform: 'translateY(-110px)' }}
                className={`${userPlantProgress.numberOfWater >= cowUnlock ? 'animalBtnContainerUnlocked' : 'animalBtnContainer'} ${userPlantProgress.numberOfWater >= cowUnlock ? showCow ? "activePet" : "unactivePet" : ''}`} onClick={handleShowCow}>
                <Popover content={`Bò sẽ được mở khoá ở cấp ${cowUnlock}`} placement="top">
                    <LockFilled className="lock" />
                </Popover>
                <img className="cowImg" src="./Assets/cowpet.png" />
            </div>
            {
                showCow && <Cow active />
            }

            {/* Firework */}
            <AnimatePresence>
                {showFirework && <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 0.5 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.7 }}
                >
                    <Fireworks
                        ref={fireWork}
                        options={{ opacity: 0.5 }}
                        style={{
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            position: 'fixed',
                            background: '#000'
                        }}
                    />
                </motion.div>}
            </AnimatePresence>


        </div>
    )
}

export default InteractedTree